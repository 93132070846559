

































import Vue, { PropOptions } from 'vue';
import TransitionAnimation from '@/components/transition.vue';
import SmartBannerMixin from '@/mixins/SmartBannerMixin';
import { isMobile, NOOP } from '@/utils';

import PageScroll from '@/libs/PageScroll';

export default Vue.extend({
  name: 'ActionSheet',
  components: {
    TransitionAnimation,
  },
  mixins: [
    SmartBannerMixin,
  ],
  props: {
    title: {
      type: String,
      default: '',
    } as PropOptions<string>,
    active: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    // введена для того, чтобы закрыть, а потом соощить об этом
    // например, скрыть с текущими элементами, открыть с дефолтным набором элементов,
    // или скрыть с заполненными полями (input, textarea...), открыть - с пустыми
    isShow: false,
    curretPosY: 0,
    startPosY: 0,
    transform: '',
  }),
  computed: {
    isEnabled (): boolean {
      return this.isShow && this.active;
    },
    actionSheetClass (): any {
      return {
        // @ts-ignore
        'lh-action-sheet--smart-banner': this.mixin__smartBanner__isSmartBanner,
      };
    },
    isMobile (): boolean {
      return isMobile();
    },
  },

  destroyed (): void {
    this.onAfterLeave();
  },

  methods: {
    NOOP,

    onClick (): void {
      this.isShow = false;
    },

    onAfterEnter (): void {
      PageScroll.disable();
      this.isShow = true;
      this.transform = '';
    },

    onAfterLeave (): void {
      if (!isMobile()) { return; }
      PageScroll.enable();
    },

    onAfterLeaveContent (): void {
      this.$emit('update:active', false);
    },

    activateDrag (e: TouchEvent): void {
      this.startPosY = e.touches.item(0)?.clientY || e.changedTouches.item(0)?.clientY || 0;
    },

    endDrag (e: TouchEvent): void {
      const actionSheet = e.target as HTMLElement;
      this.startPosY = 0;
      if (this.curretPosY <= actionSheet?.offsetHeight / 2) {
        this.transform = 'transition: .3s transform linear; transform: translateY(0);';
      } else {
        this.isShow = false;
      }
    },

    onPointermove (e: TouchEvent): void {
      this.curretPosY = -(
        this.startPosY - (e.touches.item(0)?.clientY || e.changedTouches.item(0)?.clientY || 0)
      );
      if (this.curretPosY < 0) {
        return;
      }
      this.transform = `transform: translateY(${this.curretPosY}px)`;
    },
  },
});
