
























import Vue, { PropType } from 'vue';
import clickOutside from '@/directives/clickOutside';
import TransitionAnimation from '@/components/transition.vue';
import ActionSheet from '@/components/lh-ui/ActionSheet/ActionSheet.vue';
import { isElementBeyondWindow, isElementBeyondPage } from '@/utils/intersectionElement';

type TPositionMenu = 'bottom' | 'top';

export default Vue.extend({
  name: 'ContextMenu',
  directives: {
    clickOutside,
  },
  components: {
    ActionSheet,
    TransitionAnimation,
  },
  props: {
    enabled: {
      type: Boolean,
      default: false,
    },
    isOpenContextMenu: {
      type: Boolean,
      required: true,
    },
    offsets: {
      type: Object as PropType<TOffsets | null>,
      default: null,
    },
    forceMenuPosition: {
      type: String as PropType<TPositionMenu>,
      default: undefined,
    },
    align: {
      type: String as PropType<string>,
      default: 'left',
    },
  },
  data: () => ({
    positionMenu: 'bottom' as TPositionMenu, // bottom or top
  }),
  computed: {
    contextMenuClass (): object {
      return {
        'lh-context-menu--open': this.isShowContextMenu,
      };
    },
    contextMenuStyle (): Record<string, string | undefined> {
      const {
        top = 0, left = 0, right = 0, bottom = 0,
      } = this.offsets || {};

      return {
        '--topOffset': `${top}px`,
        '--bottomOffset': `${bottom}px`,
        '--leftOffset': `${left}px`,
        '--rightOffset': `${right}px`,
      };
    },
    isShowContextMenu (): boolean {
      return this.isOpenContextMenu && this.enabled;
    },
    menuClass (): any {
      return {
        [`lh-context-menu__menu--${this.positionMenu}`]: true,
        [`lh-context-menu__menu--${this.align}`]: true,
      };
    },
  },
  watch: {
    async isShowContextMenu (newValue): Promise<void> {
      // Если передано значение не пересчитывает позицию,
      // всегда отображаем в виде который передан в props
      if (this.forceMenuPosition) {
        this.positionMenu = this.forceMenuPosition;
        return;
      }

      if (newValue) {
        await this.$nextTick();
        const menu = this.$refs.menu as HTMLElement;

        type TBooleanProperty = {
          [key: string]: string;
        };

        // сохраняем текущие стили
        const menuTempStyles: TBooleanProperty = {
          display: menu.style.display,
          visibility: menu.style.visibility,
          bottom: menu.style.bottom,
          top: menu.style.top,
        };

        // стили для проверки
        const stylesFoCheck: TBooleanProperty = {
          display: 'block',
          visibility: 'hidden',
        };

        // применяем стили для проверки
        Object.keys(stylesFoCheck).forEach((key: any) => {
          menu.style[key] = stylesFoCheck[key];
        });

        // умещается ли сверху
        menu.style.top = '100%';
        menu.style.bottom = 'auto';
        const isBeyondBottomMenu = isElementBeyondWindow(menu, 'bottom', this.offsets?.bottom ? this.offsets.bottom : 0);
        const isBeyondBottomMenuPage = isElementBeyondPage(menu, 'bottom', this.offsets?.bottom ? this.offsets.bottom : 0);

        // умещается ли снизу
        menu.style.bottom = '100%';
        menu.style.top = 'auto';
        const isBeyondTopMenu = isElementBeyondWindow(menu, 'top', this.offsets?.top ? this.offsets.top : 0);
        const isBeyondTopMenuPage = isElementBeyondPage(menu, 'top', this.offsets?.top ? this.offsets.top : 0);

        // восстанавливаем стили
        Object.keys(menuTempStyles).forEach((key: any) => {
          menu.style[key] = menuTempStyles[key];
        });

        if (isBeyondBottomMenuPage || isBeyondTopMenuPage) {
          this.positionMenu = !isBeyondTopMenuPage || isBeyondBottomMenuPage
            ? 'top'
            : 'bottom';
        } else {
          this.positionMenu = !isBeyondBottomMenu || isBeyondTopMenu
            ? 'bottom'
            : 'top';
        }
      }
    },
  },
  methods: {
    onUpdateActionSheetActive (active: boolean): void {
      this.$emit('update:isOpenContextMenu', active);
    },
    onClickOutside (): void {
      if (this.isOpenContextMenu) {
        this.$emit('update:isOpenContextMenu', false);
      }
    },
  },
});
