




























































import Vue, { PropType } from 'vue';

import { Img } from '@devhacker/shared/utils/image/types';
import TheLinkUi from '@/components/lh-ui/TheLinkUi/TheLink.vue';
import ArticleCardControls from '@/components/lh-ui/ArticleCardControls/ArticleCardControls.vue';
import AdminControlsPanel from '@/components/lh-ui/AdminControlsPanel/AdminControlsPanel.vue';
import ArticleCardMeta from '@/components/lh-ui/ArticleCardMeta/ArticleCardMeta.vue';

import articleCardMixin from '@/mixins/ArticleCard';
import AppPicture from '@/components/lh-ui/AppPicture/AppPicture.vue';

export default Vue.extend({
  name: 'ArticleCardSmall',
  components: {
    ArticleCardControls,
    TheLinkUi,
    AdminControlsPanel,
    ArticleCardMeta,
    AppPicture,
  },
  mixins: [articleCardMixin],
  props: {
    disableBreadcrumbCircularLink: {
      type: Boolean,
      default: false,
    },

    // * _mixin_getArticleProps
    url: {
      type: String as PropType<string>,
      required: true,
    },
    internalUrl: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    title: {
      type: String as PropType<string>,
      required: true,
    },
    imgAlt: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    imgLoading: {
      type: String as PropType<'eager' | 'auto' | 'lazy'>,
      default: 'lazy',
    },
    type: {
      type: String as PropType<string>,
      default: '',
    },
    images: {
      type: Array as PropType<Img[]>,
      required: false,
      default: () => [],
    },
    defaultImages: {
      type: Array as PropType<Img[]>,
      required: false,
      default: () => [],
    },
    dateText: {
      type: String as PropType<string>,
      required: true,
    },
    categories: {
      type: Array as PropType<Array<IArticleCategory>>,
      required: false,
      default: () => [],
    },
    tags: {
      type: Array as PropType<Array<IArticleTags>>,
      required: false,
      default: () => [],
    },
    commentsEnabled: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    commentsCount: {
      type: Number as PropType<number>,
      required: false,
      default: 0,
    },
    authorText: {
      type: String as PropType<string>,
      required: false,
      default: null,
    },
    authorUrl: {
      type: String as PropType<string>,
      required: false,
      default: null,
    },
    isAdvertising: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isPromo: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isTeaser: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isAuthenticatedInWordpress: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    editLinkHref: {
      type: String as PropType<string>,
      required: false,
      default: null,
    },
    viewsCount: {
      type: Number as PropType<number>,
      required: false,
      default: null,
    },
    user: {
      type: Object as PropType<IUser>,
      default: null,
    },
    favorite: {
      required: false,
      type: Object as PropType<IFavoriteItem | null>,
      default: null,
    },
    folders: {
      required: true,
      type: Object as PropType<IFolders>,
    },
    isShowFavoriteFolderName: {
      type: Boolean,
      default: false,
    },
    favoriteMenuOffsets: {
      type: Object as PropType<TOffsets | null>,
      default: null,
    },
    loadingViews: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    coverSize: {
      type: String as PropType<string>,
      default: '',
    },
    advertLabel: {
      default: '',
      type: String as PropType<'empty' | 'partner' | 'abs' | 'press-release' | 'social-ads'>,
    },
    partnerText: {
      type: String as PropType<string>,
      default: '',
    },
  },
  methods: {
    onMouseOver (): void {
      const articleCard = this.$refs.articleCard as HTMLElement;
      if (articleCard) {
        articleCard.classList.remove('lh-article-card--no-hover');
      }
    },
  },
});
